import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";
import { formatDistance } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NotificationMessageViewModal from "./NotificationMessageViewModal";
import List from "@components/layout/List";
import Tag from "@components/shared/Tag";
import Tooltip from "@components/shared/Tooltip";
import Table from "@components/layout/Table";
import CheckCircleIcon from "@components/icons/CheckCircleIcon";
import SpinnerIcon from "@components/icons/SpinnerIcon";
import { useAppContext } from "@context/AppContext";
import {
  deleteNotification,
  markNotificationAsRead,
  NotificationLevels,
} from "@services/notification";

type NotificationListProps = {
  notifications: any[];
  pageSize?: number;
  showTags?: boolean;
  onView: (notification: any) => void;
  isTable?: boolean;
  tableClassName?: string;
  tableContainerClassName?: string;
};

const NotificationList: React.FunctionComponent<NotificationListProps> = ({
  notifications,
  pageSize,
  showTags,
  onView,
  isTable = false,
  tableClassName,
  tableContainerClassName,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { loggedInInfo } = useAppContext();
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const { mutateAsync: markAsRead, isLoading } = useMutation(
    (notification: any) =>
      markNotificationAsRead({
        notificationId: notification.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "notifications",
          { id: loggedInInfo.userDetails?.id },
        ]);
      },
    },
  );
  const { mutateAsync: removeNotification } = useMutation(
    (notification: any) =>
      deleteNotification({
        notificationId: notification.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "notifications",
          { id: loggedInInfo.userDetails?.id },
        ]);
      },
    },
  );

  const handleViewNotification = (notification: any) => {
    markAsRead(notification);
    onView(notification);
  };

  const handleFollowUpNotificationClick = (notification: any) => {
    markAsRead(notification);
    window.location.href = `/polestar/notifications/create?notificationId=${
      notification.id
    }&subscriberIds=${notification.relatedSubscribers.map((s: any) => s.id)}`;
  };

  const handleViewCustomerClick = (notification: any) => {
    navigate(
      `/polestar/subscribers/${notification.relatedSubscribers?.[0]?.id}`,
    );
  };

  const handleDeleteNotificationClick = (notification: any) => {
    if (window.confirm(t("notification.delete_confirm") as string)) {
      removeNotification(notification);
    }
  };

  const percentage = (level: NotificationLevels): number => {
    switch (level) {
      case NotificationLevels.Low:
        return 90;
      case NotificationLevels.Medium:
        return 95;
      case NotificationLevels.High:
        return 99;
      case NotificationLevels.Critical:
        return 100;
      default:
        return 0;
    }
  };

  const getNotificationMessage = (notification: any) => {
    return t(notification.type?.titleTextTemplate, {
      customerName: notification.relatedSubscribers?.[0]?.name,
      senderName: notification.relatedMessage?.sender?.isAdmin
        ? t("common.administrators")
        : notification.relatedMessage?.sender?.name,
      percentage: percentage(notification.level),
    });
  };

  const notificationTableData = notifications.map((notification: any) => {
    return {
      message: (
        <div className="overflow-hidden whitespace-normal line-clamp-2 gap-1">
          <span>
            {notification.level > 0 && (
              <ExclamationCircleIcon
                className={twMerge(
                  "text-orange-500 inline align-middle mr-1 h-[16px] w-[16px]",
                  notification.level === 1 && "text-yellow-500",
                  notification.level === 2 && "text-orange-500",
                  notification.level === 3 && "text-red-500",
                  notification.level === 4 && "text-gray-900",
                )}
              />
            )}
            {getNotificationMessage(notification).length > 50
              ? getNotificationMessage(notification).substring(0, 50) + "..."
              : getNotificationMessage(notification)}
          </span>
        </div>
      ),
      date: formatDistance(new Date(notification.createdAt), new Date(), {
        addSuffix: true,
      }),
      actions: (
        <div className="flex items-center w-full gap-2">
          <button
            className="btn-primary text-sm py-1.5 px-4"
            onClick={() => setSelectedNotification(notification)}
          >
            {t("common.view")}
          </button>
          <button
            type="button"
            className="text-gray-400 hover:text-gray-600 float-right"
            onClick={e => {
              e.stopPropagation();
              markAsRead(notification);
            }}
          >
            {isLoading ? (
              <SpinnerIcon width="20" height="20" className="text-primary-1" />
            ) : (
              <TrashIcon className="w-5 h-5" />
            )}
          </button>
        </div>
      ),
      ...notification,
    };
  });
  const tableFields = [
    {
      title: t("notification.message"),
      name: "message",
    },
    {
      title: t("common.date"),
      name: "date",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  console.log(notifications?.[0]);

  return (
    <>
      {isTable ? (
        <Table
          className={tableClassName}
          containerClassName={tableContainerClassName}
          data={notificationTableData}
          fields={tableFields}
          pageSize={pageSize}
          paginationClassName="bg-inherit"
          noRowsText={t("common.notification_no_data")}
        />
      ) : (
        <List
          items={notifications.map(notificationItem => (
            <div
              key={notificationItem.id}
              className={twMerge(
                "border border-gray-200 rounded-lg p-3 cursor-pointer transition-all",
                // notificationItem.level === 1 &&
                //   "border-gray-100 hover:bg-gray-50",
                notificationItem.level === 1 &&
                  notificationItem.type?.name ===
                    "BalanceExhaustedToCustomer" &&
                  "border-yellow-100 bg-yellow-50 hover:bg-yellow-100",
                notificationItem.level === 2 &&
                  "border-orange-100 bg-orange-50 hover:bg-orange-100",
                notificationItem.level === 3 &&
                  "border-red-100 bg-red-50 hover:bg-red-100",
                notificationItem.level === 4 &&
                  "border-gray-100 bg-gray-50 hover:bg-gray-100",
              )}
              onClick={() => handleViewNotification(notificationItem)}
            >
              <div className="text-sm flex items-start gap-2">
                {notificationItem.level > 0 && (
                  <ExclamationCircleIcon
                    className={twMerge(
                      "w-5 h-5 shrink-0",
                      notificationItem.level === 1 && "text-yellow-500",
                      notificationItem.level === 2 && "text-orange-500",
                      notificationItem.level === 3 && "text-red-500",
                      notificationItem.level === 4 && "text-gray-500",
                    )}
                  />
                )}
                <h3 className="grow">
                  {getNotificationMessage(notificationItem)}
                  {notificationItem.createdAt && (
                    <span className="flex flex-row gap-1.5 text-gray-500 text-xs mt-1">
                      {formatDistance(
                        new Date(notificationItem.createdAt),
                        new Date(),
                        {
                          addSuffix: true,
                        },
                      )}
                    </span>
                  )}
                </h3>
                {!notificationItem.viewedAt && showTags && (
                  <Tag className="bg-orange-100 border-orange-700 text-orange-700 text-xs rounded-2xl px-2 py-0.5 opacity-50 shrink-0">
                    {t("common.new")}
                  </Tag>
                )}
              </div>
              <div className="flex justify-between items-center mt-4 flex-row-reverse">
                <Tooltip message={t("notification.delete")}>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-red-600 float-right transition-colors"
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteNotificationClick(notificationItem);
                    }}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                </Tooltip>
                <div className="flex gap-1">
                  {notificationItem.relatedSubscribers?.length > 0 &&
                    (notificationItem.actionedAt ? (
                      <Tag
                        status="success"
                        className="flex items-center gap-1 opacity-60"
                      >
                        <CheckCircleIcon className="w-4 h-4" /> Action completed
                      </Tag>
                    ) : (
                      <button
                        type="button"
                        className="btn-secondary text-sm py-1"
                        onClick={e => {
                          e.stopPropagation();
                          handleFollowUpNotificationClick(notificationItem);
                        }}
                      >
                        {t("notification.new_notification")}
                      </button>
                    ))}
                  {notificationItem.relatedSubscribers?.length === 1 && (
                    <button
                      type="button"
                      className="btn-default text-sm py-1"
                      onClick={e => {
                        e.stopPropagation();
                        handleViewCustomerClick(notificationItem);
                      }}
                    >
                      {t("common.customer_dashboard")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          pageSize={pageSize}
        />
      )}
      <NotificationMessageViewModal
        message={selectedNotification?.relatedMessage}
        notification={selectedNotification}
        onClose={() => {
          setSelectedNotification(undefined);
        }}
      />
    </>
  );
};

export default NotificationList;
